<template>
  <div id="LoginV2">
    <div class="loginDarkModeContainer" v-if="darkModeAvailable">
      <DarkModeToggler :nav_elements="nav_elements"/>
    </div>
    <div class="loginContainer">
      <div class="innerLoginContainer">
        <div class="logoContainer">
          <div class="innerLogoContainer">
            <img
                ref="navLogo"
                :src="getLogo"
                alt="company logo"
                @error="imageErrorHandler"
            />
          </div>
        </div>


        <ResetPasswordV2 v-if="reset"/>
        <ForgotPasswordV2 v-else-if="forgotPassword" @signIn="forgotPassword = false"/>
        <UserLogin v-else-if="!isSignUp" @switchForm="switchForm" @forgotPassword="forgotPassword = true"/>
        <UserRegister @switchForm="switchForm" v-else/>
      </div>
    </div>
    <div class="imageContainer">
      <div class="wording">
        <div>ENGAGE.</div>
        <div>EMPOWER.</div>
        <div>TRANSFORM.</div>
      </div>
    </div>
  </div>
</template>


<script>

import UserLogin from "@/components/NotLoggedIn/UserLogin.vue";
import DarkModeToggler from "@/components/Home/New/DarkModeToggler.vue";
import UserRegister from "@/components/NotLoggedIn/UserRegister.vue";
import ForgotPasswordV2 from "@/components/NotLoggedIn/ForgotPasswordV2.vue";
import ResetPasswordV2 from "@/components/NotLoggedIn/ResetPasswordV2.vue";
import {mapGetters} from "vuex";

export default {
  components: { UserLogin, DarkModeToggler, UserRegister, ForgotPasswordV2, ResetPasswordV2 },
  name: "LoginV2",
  props: ['reset'],
  data() {
    return {
      isSignUp: false,
      forgotPassword: false,
      errorWithLogo: false,
      defaultLightLogo: '/img/spectrum_life_logo_light.png',
      defaultDarkLogo: '/img/spectrum_life_logo_dark.png',
    }
  },
  mounted() {
    this.setCookie('slDomainName',window.location.hostname,20)
  },
  methods: {
    imageErrorHandler(e) {
      this.errorWithLogo = true;
      e.target.src=this.darkMode ? this.defaultDarkLogo : this.defaultLightLogo;
    },
    getLogo() {
      if (this.hardcoded && this.hardcoded.client_logo) {
        return this.hardcoded.client_logo;
      } else if (this.skin === 8) {
        return "/img/boi/boi-logo-alt.png";
      } else {
        if(this.client.client_logo === "https://healthcoach-media.com/res/images/esp/customise/spectrum-life-logo.png") {
          return this.defaultLightLogo;
        }
        return this.client.client_logo
      }
    },
    setCookie(cname, cvalue, exhrs) {
      const d = new Date();
      d.setTime(d.getTime() + (exhrs*60*1000));
      let expires = "expires="+ d.toUTCString();
      console.log(this.cookieDomain)
      document.cookie = cname + "=" + cvalue + "; " + expires + "; path=/; domain=" + this.cookieDomain;
    },
    switchForm() {
      this.isSignUp = !this.isSignUp;
    },
  },
  computed: {
    ...mapGetters(["skinsWithDarkMode","skin","default_nav_elements","navElements","client","hardcoded"]),
    cookieDomain() {
      const domainName = window.location.hostname;
      let startIndex = process.env.NODE_ENV === 'production' || process.env.NODE_ENV === 'development' ? domainName.indexOf("spectrum") : domainName.indexOf("surge-qa");
      return domainName.substring(startIndex);
    },
    nav_elements() {
      let els = this.default_nav_elements;
      Object.entries(this.navElements).forEach(el => {
        const [key,value] = el;
        if(els.hasOwnProperty(key)) {
          els[key] = value.replace('&#39;',"'");
        }
      });
      return els;
    },
    number_of_signups() {
      if(this.client.number_of_signups < 100) return 0;
      const numOfZeros = this.client.number_of_signups.toString().length - 1;
      return Math.floor(this.client.number_of_signups / Math.pow(10,numOfZeros)) * Math.pow(10,numOfZeros);
    },
    darkModeAvailable() {
      return this.skinsWithDarkMode.includes(this.skin)
    },
  },
  watch: {
  }
};

</script>



<style lang="less">
#LoginV2 {
  overflow: hidden;
  width: 100vw;
  position: relative;
  height: 100vh !important;
  display: grid;
  grid-template-columns: 700px 1fr;
  .logoContainer {
    padding: 10px 100px;
    .innerLogoContainer {
      background-color: white;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      border-radius: 20px;
      img {
        width: 400px;
        display: block;
      }
    }
  }
  .loginDarkModeContainer {
    position: absolute;
    z-index: 9999;
    top: 0;
    right: 0;
    height: 56px;
    width: 186px;
    color: white;
    background-color: #1d1d1b;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom-left-radius: 30px;
    padding: 24px;
    .dark-mode-container {
      display: grid !important;
      grid-template-columns: 1fr fit-content(100%);
      grid-column-gap: 5px;
      width: 100% !important;
    }
  }
  .loginContainer {
    height: 100%;
    width: 100%;
    background-color: white;
    padding: 58px 5px 20px 5px;
    z-index: 3;
    overflow: hidden;
    .innerLoginContainer {
      height: 100%;
      width: 100%;
      overflow: auto;
      display: flex;
      flex-direction: column;
      &::-webkit-scrollbar {
        width: 12px;
      }
      &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 2px rgba(0,0,0,0.5);
        border-radius: 10px;
      }
      &::-webkit-scrollbar-thumb {
        //background: linear-gradient(90deg, #ED3A75 0%, #6A356A 100%);
        -webkit-box-shadow: inset 0 0 2px rgba(0,0,0,0.5);
        border-radius: 10px;
      }
    }
  }
  .imageContainer {
    background-image: url("/img/home/new/homeimage.webp");
    background-repeat: no-repeat;
    background-size: cover;
    .wording {
      padding-top: 58px;
      color: #FFF;
      font-family: Poppins-SemiBold, Arial, Helvetica, sans-serif;
      font-size: 40px;
      font-style: normal;
      font-weight: 600;
      line-height: 58px;
      padding-left: 40px;
    }
  }
  @media only screen and (min-width: 1800px) {
    .imageContainer{
      background-position: center;
    }
  }
  @media only screen and (max-width: 1200px) {
    grid-template-columns: 1fr;

    .imageContainer {
      display: none;
    }
  }
  @media only screen and (max-width: 576px) {
    .logoContainer {
      padding: 10px 10px;
    }
    .innerLoginContainer {
      overflow-x: hidden !important;
    }
    img {
      max-width: 300px;
    }
    #UserLogin, #UserRegister, #ForgotPasswordV2 {
      padding: 5px;
    }
  }
}
</style>