<template>
  <div id="WorkoutPlan">

    <div class="bg-grey py-5 loading" v-if="loadingPlan && loadingWorkout">
      <h3>{{ componentText.loadingPlan }}</h3>
      <b-spinner></b-spinner>
    </div>
    <div v-else>
      <div class="bg-grey">
        <div class="content-container row position-relative">
          <div class="back-link" @click="goBack">
            <i class="fa-solid fa-arrow-left"></i> Back
          </div>
          <link
            rel="stylesheet"
            href="https://use.fontawesome.com/releases/v5.2.0/css/all.css"
            integrity="sha384-hWVjflwFxL6sNzntih27bfxkr27PmbbK/iSvJ+a4+0owXq79v+lsFkW54bOGbiDQ"
            crossorigin="anonymous"
          />
          <div class="col-12 text-center">
            <h3>{{ currentPlan.plan_title }}</h3>
          </div>
          <div class="col-12">
            <div class="slide-line"></div>
            <slick
              v-if="currentPlan.workouts"
              ref="slick"
              :options="slickOptions"
            >
              <div
                v-for="(workout, i) in currentPlan.workouts"
                :class="{
                  active: workout.workout_id == currentPlan.current_workout_id,
                }"
                class="pt-5 workout-slide"
              >
                <a
                  @click="selectWorkout(workout.workout_id)"
                  class="brand-secondary border-brand-highlight-1 pb-3"
                  >{{ componentText.workout }} {{ i + 1 }}</a
                >
              </div>
            </slick>
          </div>
        </div>
        <div class="content-container row loading" v-if="loadingWorkout">
          <h3>{{ componentText.loadingWorkout }}</h3>
          <b-spinner></b-spinner>
        </div>
        <div class="content-container row" v-else>
          <div class="col-12">
            <slick
              v-if="currentWorkout.length > 0"
              ref="slick1"
              :options="slickOptions"
            >
              <b-link
                v-for="(exercise, i) in displayedWorkout"
                :key="i"
                class="pt-5 exercise-slide"
                :class="{
                  active:
                    exercise.exercise_plan_id.toString() ===
                    currentExercise.sur_exercise_plan_id,
                }"
                @click="selectExercise(exercise.exercise_plan_id)"
              >
                <div class="slide-image border-brand-highlight-1">
                  <img :src="exercise.exercise_img" />
                </div>
                <div class="slide-content bg-brand-secondary py-4">
                  <p>{{ exercise.exercise_name }}</p>
                  <p class="mx-3" v-if="exercise.exercise_plan_type === 'time'">
                    {{ exercise.exercise_plan_time_secs }}
                    {{ componentText.seconds }}
                  </p>
                  <p class="mx-3" v-else>
                    {{ exercise.exercise_plan_reps }} {{ componentText.reps }}
                  </p>
                </div>
              </b-link>
            </slick>
          </div>
        </div>
      </div>
      <div id="exercise-scroll" class="bg-white py-5">
        <b-row class="content-container selected-exercise py-5">
          <div
            class="col-12 col-md-6 mb-5 pb-5"
            v-if="currentExercise.exercise_video_sq"
          >
            <GAVideoPlayer
              :video-class="'w-100 h-100 video'"
              :video-ref="'exerciseVideo'"
              :src="currentExercise.exercise_video_sq"
              @canplay="resetVideoHeight"
            />
          </div>
          <div
            v-if="currentExercise.exercise_name"
            class="col-12 col-md-6 workout-info"
          >
            <b-row>
              <div class="col-12 col-sm-6 text-center text-sm-left">
                <h4>{{ currentExercise.exercise_name }}</h4>
              </div>
              <div class="col-12 col-sm-6 text-center" v-if="$store.getters.subdomain !== 'pwcire'">
                <b-button
                v-if="!$store.getters.user.access_code_user"
                  @click="logExercise(currentExercise.sur_exercise_plan_id)"
                  class="cta-button px-5 py-3"
                  >{{ componentText.logExercise }}</b-button
                >
              </div>
              <div
                class="col-12 exercise-equipment my-5 py-4"
                v-if="displayEquipment"
              >
                <h5>
                  <img src="/img/fitness/equipment.svg" class="mr-4" />{{
                    componentText.equipment
                  }}
                  {{ currentExercise.exercise_equipment }}
                </h5>
              </div>
              <div class="col-12 mb-5">
                <h5>{{ componentText.how }}</h5>
                <p>{{ currentExercise.exercise_how_to }}</p>
              </div>
              <div class="col-12">
                <h5>{{ componentText.where }}</h5>
                <p>{{ currentExercise.exercise_where_feel }}</p>
              </div>
            </b-row>
          </div>
          <div class="col-12 text-center event-content">
            <h4 class="disclaimer-heading pt-4">
              {{ componentText.disclaimerHeading }}
            </h4>
            <a
              v-b-toggle.disclaimer
              class="text-link"
              v-if="!disclaimerVisible"
              >{{ componentText.showMore }}</a
            >
            <b-collapse
              id="disclaimer"
              v-model="disclaimerVisible"
              class="my-5"
              v-html="componentText.disclaimerContent"
            >
            </b-collapse>
              <a
                v-b-toggle.disclaimer
                class="text-link"
                v-if="disclaimerVisible"
                >{{ componentText.showLess }}</a
              >
          </div>
        </b-row>
      </div>
      <div v-if="exerciseLogged" class="log-confirmation">
        {{ componentText.logged }} <i class="fas fa-check"></i>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import axios from "axios";
import config from "../../config/constants";
import slick from "vue-slick";
import GAVideoPlayer from "../GAVideoPlayer.vue";
export default {
  name: "WorkoutPlan",
  components: {
    GAVideoPlayer,
    slick,
  },
  props: ["plan"],
  data() {
    return {
      disclaimerVisible: false,

      loadingPlan: true,
      loadingWorkout: true,
      loadingExercise: true,
      currentPlan: {},
      currentWorkout: [],
      currentExercise: {
        exercise_video_sq: "",
        sur_exercise_plan_id: "",
      },
      exerciseLogged: false,
      slickOptions: {
        infinite: false,
        autoplay: false,
        dots: false,
        nextArrow:
          '<button class="slick-next  py-1" type="button" style=""><i class="fas fa-chevron-right brand-secondary"></i></button>',
        prevArrow:
          '<button class="slick-prev  py-1" type="button" style=""><i class="fas fa-chevron-left brand-secondary"></button>',
        initialSlide: 0,
        slidesToScroll: 1,
        slidesToShow: 5,
        responsive: [
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 3,
            },
          },
          {
            breakpoint: 576,
            settings: {
              slidesToShow: 2,
            },
          },
        ],
      },
    };
  },
  mounted() {
    this.getCurrentWorkoutPlan();
    this.loadingPlan = false;
  },
  methods: {
    goBack() {
      this.$emit('showWorkoutPlans');
    },
    async getCurrentWorkoutPlan() {
      const payload = {
        user_id: this.$store.getters.user_id,
        plan_id: this.plan,
      };
      let res = await axios.post(
        config.api_env + "/application/api-hc/get-current-workout-exercise",
        payload,
        config.options
      );
      if (res.data.success) {
        this.currentPlan = res.data;
        this.getWorkout(this.currentPlan.current_workout_id);
      }
    },
    selectWorkout(workoutId) {
      this.currentPlan.current_workout_id = workoutId;
      this.getWorkout(workoutId);
    },
    async getWorkout(workoutId) {
      this.loading = true;
      const payload = {
        user_id: this.$store.getters.user_id,
        plan_id: this.plan,
        workout_id: workoutId,
      };
      let res = await axios.post(
        config.api_env + "/application/api-hc/get-exercises-by-workout",
        payload,
        config.options
      );
      if (res.data.success) {
        this.currentWorkout = res.data.exercises;
        this.selectExercise(this.currentWorkout[0].exercise_plan_id);
        this.loadingWorkout = false;
      }
    },
    async selectExercise(id) {
      if (this.$refs.exerciseVideo) {
        //if there's already a video there, set the hight of the video in css to avoid spazzy view
        this.$refs.exerciseVideo.style.height =
          this.$refs.exerciseVideo.clientHeight + "px";
      }
      const payload = {
        user_id: this.$store.getters.user_id,
        exercise_plan_id: id,
      };
      let res = await axios.post(
        config.api_env + "/application/api-hc/get-surge-exercise",
        payload,
        config.options
      );
      if (res.data.success) {
        this.currentExercise = res.data.exercise;
        const scrollTo = document.getElementById("exercise-scroll");
        scrollTo.scrollIntoView({ behavior: "smooth" });
      }
    },
    async logExercise(id) {
      const payload = {
        user_id: this.$store.getters.user_id,
        exercise_plan_id: id,
      };
      let res = await axios.post(
        config.api_env + "/application/api-hc/log-surge-exercise",
        payload,
        config.options
      );
      if (res.data.success) {
        this.exerciseLogged = true;
        setTimeout(() => (this.exerciseLogged = false), 5000);
      }
    },
    resetVideoHeight() {
      //needed to make the video responsive, after height was fixed before swap.
      this.$refs.exerciseVideo.style.height = "auto";
    },
  },
  watch: {
    displayedWorkout() {
      this.$nextTick(() => {
        this.$refs.slick1.reSlick();
      });
    },
  },
  computed: {
    displayedWorkout() {
      return this.currentWorkout.filter(
        (exercise) => exercise.exercise_plan_type !== "rest"
      );
    },
    componentText() {
      return this.getText.loggedIn.fitness.workoutPlan;
    },
    displayEquipment() {
      if (
        this.currentExercise.exercise_equipment.toLowerCase() ===
          "body weight" ||
        this.currentExercise.exercise_equipment.toLowerCase() ===
          "no equipment required"
      ) {
        return false;
      } else return true;
    },
  },
};
</script>
<style lang="less">
#WorkoutPlan {
  .back-link {
    color: white;
    position: absolute;
    font-size: 16px;
    top: 20px;
    right: 0;
    cursor: pointer !important;
    z-index: 20;
  }
  .event-content {
    margin: auto;
    max-width: 750px;
    background-color: #fff;
    border-radius: 8px;
    p,
    li {
      text-align: left;
      color: #666666 !important;
      font-size: 1.6rem;
    }
    h2 {
      font-family: ff-cocon-web-pro, sans-serif;
    }
    h3,
    h4 {
      text-align: center;
      color: #666666;
      font-family: "Open Sans", sans-serif;
    }
    .text-link {
      font-size: 1.6rem;
      font-weight: bold;
      text-align: center;
    }
  }
  .loading {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .slide-line {
    width: 100%;
    height: 2px;
    background-color: #c1c1c1;
    opacity: 0.8;
    transform: translateY(12px);
  }
  .workout-slide {
    text-align: center;
    a {
      font-family: "FilsonProBold", sans-serif;
      font-size: 2.3rem;
      &:after {
        content: "";
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-50%, 0%);
        width: 12px;
        height: 12px;
        border-radius: 50%;
        cursor: pointer;
      }
      &:focus {
        outline: none;
      }
    }
    &.active {
      a {
        border-bottom-style: solid;
        border-bottom-width: 5px;
      }
    }
  }
  .exercise-slide {
    width: 170px;
    cursor: pointer;
    height: 100%;
    &.active,
    &:hover {
      transform: scale(1.1);
    }
    .slide-image {
      img {
        width: 100%;
      }
      border-bottom-width: 5px;
      border-bottom-style: solid;
    }
    .slide-content {
      text-align: center;
      height: 110px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      p {
        font-family: "DMSans", sans-serif;
        font-size: 1.6rem;
        color: #ffffff;
        &:last-child {
          border-top: 1px solid #fff;
        }
      }
    }
  }
  .slick-arrow.slick-disabled {
    opacity: 0.5;
  }
  .slick-next {
    transform: translate(30px, -40px);
  }
  .slick-prev {
    transform: translate(-30px, -40px);
  }
  .workout-info {
    h4 {
      font-size: 2.1rem;
      font-family: "FilsonProBold", sans-serif;
    }
    h5 {
      font-size: 1.9rem;
      font-family: "FilsonProBold", sans-serif;
      img {
        width: 30px;
      }
    }
    p {
      font-size: 1.6rem;
    }
    .exercise-equipment {
      border-width: 1px 0 1px 0;
      border-color: #c1c1c1;
      border-style: solid;
    }
  }
  .log-confirmation {
    font-size: 3.3rem;
    font-family: "FilsonProBold", sans-serif;
    color: #fff;
    background-color: rgba(0, 0, 0, 0.8);
    position: fixed;
    top: 0;
    width: 100%;
    text-align: center;
    padding: 80px 0;
    z-index: 9999;
  }
  .video {
    border-radius: 20px;
    &:focus {
      outline: none;
    }
  }
}
</style>
