<template>
  <div id="UserLogin">
    <h2 class="form-title">{{ getText.notLoggedIn.login.title }}</h2>
    <div class="newUserText" @click="switchForm">{{ getText.notLoggedIn.login.newUserText }}</div>
    <b-form class="mt-5 position-relative">
      <div class="loadingProgress" v-if="attemptingNonSsoLogin || loginTimedOut">
        <template v-if="attemptingNonSsoLogin">
          <div class="text-center" style="font-family: FilsonProBold, Arial, Helvetica, sans-serif">Checking Login Information...</div>
          <div class="loginLoader mx-auto mt-4"></div>
        </template>
        <template v-else-if="loginTimedOut">
          <img src="/img/wifi-slash.svg" alt="no wifi" class="d-block mx-auto" style="width: 30px">
          <div class="text-center mt-2" style="font-family: FilsonProBold, Arial, Helvetica, sans-serif">Connection Lost. Network Error.</div>
        </template>
      </div>
      <b-form-group
          id="input-group-1"
          :label="getText.notLoggedIn.signUp.email"
          label-for="inputEmail"
      >
        <b-form-input
            type="email"
            id="inputEmail"
            v-model="user.email"
            :state="$v.user.email.$dirty ? !$v.user.email.$error : null"
            aria-describedby="user-name-error"
        ></b-form-input>
        <b-form-invalid-feedback id="user-name-error">
          {{ getText.notLoggedIn.login.usernameWarning }}
        </b-form-invalid-feedback>
      </b-form-group>
      <b-form-group
          id="input-group-2"
          :label="getText.notLoggedIn.login.password"
          label-for="inputPass"
      >
        <div class="password-wrap">
          <b-form-input
              :type="passwordFieldType"
              id="inputPass"
              v-model="user.password"
              :state="
                    $v.user.password.$dirty ? !$v.user.password.$error : null
                  "
              aria-describedby="password-error"
              @keyup.enter.native="submitForm"
          >

          </b-form-input>
          <div class="eye" @click="switchVisibility">
            <font-awesome-icon
                v-if="passwordFieldType === 'password'"
                icon="eye"
            ></font-awesome-icon>
            <font-awesome-icon
                v-else
                icon="eye-slash"
            ></font-awesome-icon>
          </div>
        </div>
        <b-form-invalid-feedback id="password-error">
          {{ getText.notLoggedIn.login.passwordWarning }}
        </b-form-invalid-feedback>
      </b-form-group>
      <div class="row mt-5 d-flex align-items-center justify-content-center">
        <div class="forgot-password col-6" @click="$emit('forgotPassword')">{{
            getText.notLoggedIn.login.forgotPassword
          }}</div>
        <div
            class="
                submit-form
                text-center
                d-flex
                align-items-center
                justify-content-center
                col-6
              "
        >
          <b-button
              type="button"
              @click="submitForm"
              class="py-3"
              :class="{invalid: $v.user.$invalid}"
              variant="cta"
              :disabled="$v.user.$invalid"
          >{{ buttonText }}</b-button
          >
        </div>
      </div>
    </b-form>
    <div class="orDivider" v-if="showGoogleSso || showMsSso">
      <div class="divider"></div>
      <div class="or">or</div>
      <div class="divider"></div>
    </div>
    <ThirdPartyLogin v-if="showGoogleSso || showMsSso" :show-google-sso="showGoogleSso" :show-ms-sso="showMsSso"/>
    <div class="smallPrint">Protected by reCAPTCHA and subject to the <a href="/privacy-policy" target="_blank">Privacy
      Policy</a> and <a href="/terms-and-conditions" target="_blank">Terms of Service</a>.</div>
  </div>
</template>

<script>
import LanguageSelect from "@/components/LanguageSelect.vue";
import {validationMixin} from "vuelidate";
import {email, required} from "vuelidate/lib/validators";
import axios from "axios";
import config from "@/config/constants";
import {mapGetters} from "vuex";
import ThirdPartyLogin from "@/components/NotLoggedIn/ThirdPartyLogin.vue";
import EventBus from "../../helpers/eventbus";
import {tr} from "date-fns/locale";

export default {
    name: "UserLogin",
    components: {LanguageSelect, ThirdPartyLogin},
    props: [],
    mixins: [validationMixin],
    data() {
      return {
        user: {
          email: "",
          password: "",
        },
        newUser: {
          org_code: "4wSZAoYA",
        },
        isSignUp: false,
        organisation: null,
        passwordFieldType: "password",
        preventSso: true,
        attemptingNonSsoLogin: false,
        loginTimedOut: false
      };
    },
    mounted() {
      EventBus.$on('login-time-out',this.handleTimeout);
      EventBus.$on('login-unsuccessful',this.handleFailedLogin)
    },
    validations: {
      user: {
        email: {
          required,
          email
        },
        password: {
          required,
        },
      },
    },
    computed: {
    ...mapGetters(["hardcoded", "skin","client","darkMode"]),
    ...mapGetters(["hardcoded", "skin","client"]),
    buttonText() {
      if(this.loginTimedOut) return 'Try again';
      if(this.attemptingNonSsoLogin) return 'Checking details...'
      return this.getText.notLoggedIn.login.buttonText;
    },
    showGoogleSso() {
      return this.client.google_sso_enabled === 1 && !this.preventSso;
    },
    showMsSso() {
      return this.client.ms_sso_enabled === 1 && !this.preventSso;
    }
  },
    methods: {
      getLogo() {
        if (this.hardcoded && this.hardcoded.client_logo) {
          return this.hardcoded.client_logo;
        } else if (this.skin === 8) {
          return "/img/boi/boi-logo-alt.png";
        } else {
          if(this.client.client_logo === "https://healthcoach-media.com/res/images/esp/customise/spectrum-life-logo.png") {
            return this.darkMode ? this.defaultDarkLogo : this.defaultLightLogo;
          }
          return this.client.client_logo
        }
      },
      handleFailedLogin() {
        this.loginTimedOut = false;
        this.attemptingNonSsoLogin = false;
      },
      handleTimeout() {
        this.loginTimedOut = true;
        this.attemptingNonSsoLogin = false;
      },
      switchForm() {
        this.$emit('switchForm');
      },
      validateState(ref) {
        if (
            this.veeFields[ref] &&
            (this.veeFields[ref].dirty || this.veeFields[ref].validated)
        ) {
          return !this.veeErrors.has(ref);
        }
        return null;
      },
      submitForm() {
          this.$v.user.$touch();
          if (this.$v.user.$anyError) {
            return;
          }
        this.attemptingNonSsoLogin = true;
        this.signIn();
      },
      async signIn() {
        let payload = {
          username: this.user.email,
          password: this.user.password,
          encrypt_level: "high",
          platform: "web",
          lang: this.$store.state.lang,
        };
        let v3_ready = await this.$store.dispatch("signIn", {
          router: this.$router,
          payload,
          newLogin: true
        });
        if (v3_ready === false) {
          this.$emit("not-v3");
        }
      },
      async getOrgLocations() {
        let payload = { org_code: "4wSZAoYA", lang: this.$store.state.lang };
        try {
          let res = await axios.post(
              config.api_env + "/application/api-hc/get-client-signup-options",
              payload,
              config.options
          );
          this.organisation = res.data;
        } catch (e) {
          console.log(e);
        }
      },
      switchVisibility() {
        this.passwordFieldType =
            this.passwordFieldType === "password" ? "text" : "password";
      },
    },
    watch: {
      'user.email'(newValue) {
        const atIndex = newValue.indexOf('@');
        if (atIndex !== -1) {
          this.user.email = newValue.substring(0, atIndex + 1) + newValue.substring(atIndex + 1).toLowerCase();
        }
      },
      loginTimedOut(val) {
        if(val) {
          setTimeout(()=> {
            this.loginTimedOut = false;
          },5000)
        }
      }
    }
}

</script>
<style lang="css">
.loginLoader {
  width: 80%;
  height: 10px;
  background:
      linear-gradient(#604AFF) 0/0 no-repeat
      #BCB2FE;
  animation: l1 15s infinite linear;
}
@keyframes l1 {
  100% {background-size:100%}
}
</style>
<style lang="less">
  #UserLogin {
    width: 100%;
    height: 100%;
    padding: 20px 100px;
    * {
      font-family: 'DMSans', sans-serif;
    }
    .loadingProgress {
      width: calc(100% + 20px);
      position: absolute;
      left: -10px;
      height: 150px;
      background-color: #CDCDCC;
      opacity: 0.9;
      z-index: 9999;
      display: flex;
      flex-direction: column;
      font-size: 1.6rem;
      font-weight: bold;
      justify-content: center;
    }
    .logoContainer {
      height: 100px;
      width: 100%;
      img {
        float: right;
        width: 40%;
        margin-right: 10px;
      }
    }
    .form-title {
      font-size: 5rem;
      text-align: left;
      font-family: 'DMSans', sans-serif;
    }

    label {
      font-size: 1.4rem;
    }
    .newUserText {
      font-size: 1.6rem;
      cursor: pointer;
      &:hover {
        background: linear-gradient(90deg, #ED3A75 0%, #6A356A 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
    .password-wrap {
      position: relative;
      .eye {
        position: absolute;
        right: 20px;
        top: 15px;
        svg {
          scale: 1.6;
        }
      }
    }
    .forgot-password {
      font-size: 2rem;
      cursor: pointer;
    }
    .invalid-feedback {
      font-size: 1.3rem;
    }
    .orDivider {
      margin: 25px 0 15px 0;
      align-items: center;
      justify-content: center;
      display: flex;

      .divider {
        height: 1px;
        width: 43%;
        background: #DCDBDD;
      }
      .or {
        width: 14%;
        text-align: center;
        font-size: 1.5rem;
      }
    }
    .btn-cta {
      width: 75%;
      margin: auto;
      height: 50px;
      border-radius: 15px;
      font-size: 1.6rem;
      &:active &:not(.invalid) {
        transform: translateY(2px);
      }
      &.invalid {
        background: grey !important;
      }
    }
    .smallPrint {
      margin-top: 40px;
      font-size: 1.6rem;
      a {
        background: linear-gradient(90deg, #ED3A75 0%, #6A356A 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        cursor: pointer;
        &:hover {
          background: linear-gradient(90deg, #6A356A 0%, #ED3A75 100%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          cursor: pointer;
        }
      }
    }
  }
</style>