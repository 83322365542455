<template>
  <div id="CoachingEAPHome" v-if="true">
    <section id="EvolveBanner" class="evolve-home-banner-container pb-5">
      <div class="content-container">
        <div class="row evolve-home-banner mx-0">
          <div class="evolve-home-banner-text col-md-5 col-sm-12">
            <h1 class="evolve-home-banner-title">Mental Health Support</h1>
            <p class="white-text">
              Our team of <strong>mental health coaches</strong> are on hand to help you
              make the changes you want or need to make in your life. Whether it
              be emotional wellbeing, motivation, goal setting or a feeling that
              things are just a little off, our qualified and experience coaches
              are here to help.
            </p>
            <p class="white-text">
              You can choose a coach, make a video appointment, and from then
              on, you and your coach will work through the best next steps for
              you to achieve your goals.
            </p>
            <b-button
              class="cta-button px-5 py-3 my-5 shadow"
              @click="showQuestionnaireModal()"
              >Start Coaching Journey</b-button
            >

          </div>
          <div class="col-md-7 py-2 my-2 col-sm-12">
            <GAVideoPlayer
                :poster="'/img/totalMentalHealth/tmh-video-poster.png'"
                :video-class="'w-100'"
                :src="'https://spectrum-life-media.s3.eu-west-1.amazonaws.com/CONTENT/TMH/introducing_tmh_cc+(Original).mp4'"/>
          </div>
        </div>
      </div>
    </section>
    <div class="py-5 " id="EAPNumbers">
      <div class="content-container d-flex flex-column justify-content-center">
        <p>
          If you would prefer to talk with a member of the 24/7 Mental Health Support team instead you can call or text us:
        </p>
        <div class="evolve-home-eap-numbers pb-5 row mt-5">
          <div class="col-4 eap-number-box mb-4">IE: <a href="tel:+1800849205" class="telephone-link brand-text"><b>1800 849205</b></a></div>
          <div class="col-4 eap-number-box mb-4">UK: <a href="tel:+08081968161" class="telephone-link brand-text"><b>0808 1968161</b></a></div>
          <div class="col-4 eap-number-box mb-4">International: <a href="tel:+0035315180277" class="telephone-link brand-text"><b>+353 1 518 0277</b></a></div>
          <div class="col-4 eap-number-box">WhatsApp IE: <a href="https://wa.me/353873690010" class="telephone-link brand-text"><b>+353 87 369 0010</b></a></div>
          <div class="col-4 eap-number-box">SMS IE: <a href="sms:+353871452056" class="telephone-link brand-text"><b>+353 87 1452056</b></a></div>
          <div class="col-4 eap-number-box">SMS & WhatsApp UK: <a href="sms:+447418360780" class="telephone-link brand-text"><b>+44 74 1836 0780</b></a></div>
        </div>
      </div> 
    </div>
    <section
      id="EvolvePathways"
      class="bg-white"
      style="position: relative"
    >
      <div class="pathway-overlay">
        <img src="/img/evolve/pathway-wave.svg" />
      </div>
      <div class="pathway-blob">
        <div class="d-none d-md-flex flex-row justify-content-end pr-5 pb-5">
          <svg
            width="1030"
            height="465"
            viewBox="0 0 1030 465"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0.70778 224.951C6.95281 32.8981 231.101 -68.2156 379.208 54.2091L458.826 120.022C532.887 181.24 636.367 192.261 721.66 148.014L779.144 118.194C859.943 76.278 959.415 104.925 1005.54 183.395C1030.03 225.052 1035.7 275.347 1021.28 321.466C990.332 420.439 879.721 470.773 785.087 428.373L687.167 384.501C619.421 354.149 542.13 353.206 473.664 381.897L321.246 445.77C165.864 510.884 -4.76762 393.336 0.70778 224.951Z"
              fill="url(#paint0_linear)"
              fill-opacity="0.7"
            />
            <defs>
              <linearGradient
                id="paint0_linear"
                x1="-11.5749"
                y1="389.182"
                x2="1152.61"
                y2="389.182"
                gradientUnits="userSpaceOnUse"
              >
                <stop offset="0.229672" v-bind:stop-color="startColour" />
                <stop offset="0.859082" v-bind:stop-color="stopColour" />
              </linearGradient>
            </defs>
          </svg>
        </div>
      </div>

      <div class="content-container py-5" id="coachingPathways">
        <h2 >Explore Coaching <b>Pathways</b></h2>
        <p class="pathways-subheader">Our Total Mental Health Coaches are eager to support you in achieving your goals. Read below to find out more about some of the wellbeing pathways that you can work on along with your coach.</p>
        <p class="pathways-subheader">These pathways are just an initial guide for conversation with your coach but you are free to set your own goals on anything you like from resilience to burnout, our coaches are here to help.</p>
        <div class="evolve-pathways-container">
            <div class="evolve-pathway bg-brand-secondary text-white">
              <h4 class="evolve-pathway-title">Improving Sleep</h4>
              <p class="evolve-pathway-text">
                Dreaming of a better life? Join us on this journey to discovery how you can sleep your way to wellbeing.
              </p>
              <div>
                <b-button
                    class="cta-button pathway-card-button"
                    @click="showPathwayModal('/img/evolve/centered/improve-sleep.png','Improve Sleep',componentText.improveSleepText)"
                  >{{selectPathwayBtnText}}</b-button
                >
              </div>
              <img
                class="evolve-pathway-image"
                src="/img/evolve/improve-sleep-dark.png"
              />
            </div>
            <div class="evolve-pathway bg-brand-secondary text-white">
              <h4 class="evolve-pathway-title">Improving Mood</h4>
              <p class="evolve-pathway-text">
                Feeling low? Our emotions tell us about what’s missing in our lives and what we truly need for our wellbeing. Find out more here.
              </p>
              <div>
                <b-button
                    class="cta-button pathway-card-button yellow-button"
                    @click="showPathwayModal('/img/evolve/centered/improve-mood.png','Improve Mood', componentText.improveYourMoodText)"
                  >{{selectPathwayBtnText}}</b-button
                >
              </div>
              <img
                class="evolve-pathway-image" 
                src="/img/evolve/improve-mood-dark.png"
              />
            </div>
            <div class="evolve-pathway bg-brand-secondary text-white">
              <h4 class="evolve-pathway-title">Reducing Anxiety</h4>
              <p class="evolve-pathway-text">
                Anxiety can help us prepare for future challenges, but it can also rob us of our present joys. Come aboard to sail through the waves of anxiety.
              </p>
              <div>
                <b-button
                    class="cta-button pathway-card-button green-button"
                    @click="showPathwayModal('/img/evolve/centered/reducing-anxiety.png','Reducing Anxiety',componentText.transformAnxietyText)"
                  >{{selectPathwayBtnText}}</b-button
                >
              </div>
              <img
                class="evolve-pathway-image"
                src="/img/evolve/reducing-anxiety-dark.png"
              />
            </div>
            <div class="evolve-pathway bg-brand-secondary text-white">
              <h4 class="evolve-pathway-title">Stress Management</h4>
              <p class="evolve-pathway-text">
                Did you know ‘stressed’ spelt backwards is desserts? Join this challenge to transform bitter stress into something a bit sweeter.
              </p>
              <div>
                <b-button
                    class="cta-button pathway-card-button"
                    @click="showPathwayModal('/img/evolve/centered/stress-management.png','Stress Management',componentText.stressManagementText)"
                  >{{selectPathwayBtnText}}</b-button
                >
              </div>
              <img
                class="evolve-pathway-image"
                src="/img/evolve/stress-management-dark.png"
              />
            </div>
            <div class="evolve-pathway bg-brand-secondary text-white">
              <h4 class="evolve-pathway-title">Communication Skills</h4>
              <p class="evolve-pathway-text">
                Communication is so much more than just words. Join here to discover how to better express yourself and nurture meaningful connections with others.
              </p>
              <div>
                <b-button
                    class="cta-button pathway-card-button yellow-button"
                    @click="showPathwayModal('/img/evolve/centered/communication-skills.png','Communication Skills',componentText.integrativeCommunicationText)"
                  >{{selectPathwayBtnText}}</b-button
                >
              </div>
              <img
                class="evolve-pathway-image"
                src="/img/evolve/communication-skills-dark.png"
              />
            </div>
            <div class="evolve-pathway bg-brand-secondary text-white">
              <h4 class="evolve-pathway-title">Mindfulness</h4>
              <p class="evolve-pathway-text">
                Each moment is a gift. That is why it is called present. Take this moment to let go what was or what will be and awaken to the power of now.
              </p>
              <div>
                <b-button
                    class="cta-button pathway-card-button green-button"
                    @click="showPathwayModal('/img/evolve/centered/mindfulness.png','Mindfulness',componentText.mindfulnessText)"
                  >{{selectPathwayBtnText}}</b-button
                >
              </div>
              <img
                class="evolve-pathway-image"
                src="/img/evolve/mindfulness-dark.png"
              />
            </div>
        </div>
      </div>
    </section>
    <section id="EvolveReviews" class="my-5" style="position: relative">
      <div class="banner-overlay">
        <img class="wave" src="/img/evolve/banner-wave.svg" v-if="windowWidth < 2100"/>
        <img class="wave" src="/img/evolve/banner-wave-lg-screen.svg" v-if="windowWidth >= 2100"/>
      </div>
      <div class="evolve-reviews-container content-container mt-5">
        <div class="row mx-5 px-5">
          <div class="col-md-4 col-sm-12 mt-3">
            <div class="evolve-review bg-white shadow">
              <div
                  class="
                  d-flex
                  flex-column
                  align-items-center
                  justify-content-center
                "
              >
                <div
                    class="
                    evolve-review-stars
                    mb-4
                    flex-row
                    justify-content-center
                    brand-primary
                  "
                >
                  <i class="fas fa-star fa-2x px-1"></i>
                  <i class="fas fa-star fa-2x px-1"></i>
                  <i class="fas fa-star fa-2x px-1"></i>
                  <i class="fas fa-star fa-2x px-1"></i>
                  <i class="fas fa-star fa-2x px-1"></i>
                </div>
              </div>
              <p class="evolve-review-content text-center">
                Total Mental Health has given me much greater ownership of my own mental health. The blend of self-directed elearning with the support of a coach as needed allowed me to access the support most fitting my needs at the right time.
              </p>
              <div>
                <h4
                    class="
                  evolve-review-name
                  brand-highlight-1
                  mx-auto
                  text-center
                  mt-5
                  mb-1
                "
                >
                  James, Financial Adviser
                </h4>
                <div class="evolve-review-location text-center">
                  London, UK
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4 col-sm-12 mt-3">
            <div class="evolve-review bg-white shadow">
              <div
                  class="
                  d-flex
                  flex-column
                  align-items-center
                  justify-content-center
                "
              >
                <div
                    class="
                    evolve-review-stars
                    mb-4
                    flex-row
                    justify-content-center
                    brand-primary
                  "
                >
                  <i class="fas fa-star fa-2x px-1"></i>
                  <i class="fas fa-star fa-2x px-1"></i>
                  <i class="fas fa-star fa-2x px-1"></i>
                  <i class="fas fa-star fa-2x px-1"></i>
                  <i class="fas fa-star fa-2x px-1"></i>
                </div>
              </div>
              <p class="evolve-review-content text-center">
                I was initially hesitant about reaching out for support, but from the moment I started working with a mental health coach I knew I had done the right thing. Overall a professional and beneficial experience.
              </p>
              <div>
                <h4
                    class="
                  evolve-review-name
                  brand-highlight-1
                  mx-auto
                  text-center
                  mt-5
                  mb-1
                "
                >
                  Andrea, Retail Manager
                </h4>
                <div class="evolve-review-location text-center">Belfast, UK</div>
              </div>
            </div>
          </div>
          <div class="col-md-4 col-sm-12 mt-3">
            <div class="evolve-review bg-white shadow">
              <div
                  class="
                  d-flex
                  flex-column
                  align-items-center
                  justify-content-center
                "
              >
                <div
                    class="
                    evolve-review-stars
                    mb-4
                    flex-row
                    justify-content-center
                    brand-primary
                  "
                >
                  <i class="fas fa-star fa-2x px-1"></i>
                  <i class="fas fa-star fa-2x px-1"></i>
                  <i class="fas fa-star fa-2x px-1"></i>
                  <i class="fas fa-star fa-2x px-1"></i>
                  <i class="fas fa-star fa-2x px-1"></i>
                </div>
              </div>
              <p class="evolve-review-content text-center brand-text">
                Total Mental Health is truly a massive leap forward in the employee mental health and wellbeing space. I had been looking for something more than EAP to offer to my team and believe that I’ve found it in this service.
              </p>
              <div>
                <h4
                    class="
                  evolve-review-name
                  brand-highlight-1
                  mx-auto
                  text-center
                  mt-5
                  mb-1
                "
                >
                  John, SME Owner
                </h4>
                <div class="evolve-review-location text-center">Cardiff, UK</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <QuestionnaireModal
      :isVisible="showQuestionnaire"
      :user="user"
      :coachingDataComplete ="coachingDataComplete"
    ></QuestionnaireModal>
    <b-modal
      id="pathway-modal"
      v-bind:modal-class="'pathway-modal ' + getTheme()"
      hide-footer
      hide-header
    >
      <div class="" style="background-color: #403d56">
        <span class="close-button" @click="$bvModal.hide('pathway-modal')"
          ><i class="fas fa-times brand-highlight-1 fa-3x ml-4 mt-4"></i
        ></span>
        <div class="row">
          <img
            class="img-fluid px-3 py-5 mx-auto"
            :src="pathwayModalImageUrl"
          />
        </div>
        <div
          class="pathway-content-container bg-brand-secondary px-4 py-5"
          style="background-color: #3a4a61"
        >
          <h2 class="text-center">
            Start the <br /><b>{{pathwayModalTitle}} Pathway</b>
          </h2>
          <div v-html="pathwayModalText"></div>
          <div class="text-center">
            <b-button
              class="cta-button px-5 mt-3"
              @click="closePathwayModal()"
              >Close</b-button
            >
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import axios from "axios";
import config from "../../config/constants";
import slick from "vue-slick";
import QuestionnaireModal from "./QuestionnaireModal.vue";
import ProfileQuestionnaireModal from "@/components/modals/ProfileQuestionnaireModal";
import {mapState} from "vuex";
import GAVideoPlayer from "../GAVideoPlayer.vue";

export default {
  name: "CoachingEAPHome",
  components: {
    GAVideoPlayer,
    ProfileQuestionnaireModal,
    slick,
    QuestionnaireModal,
  },
  data() {
    return {
      user_info: {
        user_profile: {}
      },
      showQuestionnaire: false,
      startColour: "#381B51",
      stopColour: "#9A5683",
      courses: {},
      pathwayModalTitle: "",
      pathwayModalImageUrl: "",
      pathwayModalText: "",
      windowWidth: window.innerWidth,
      selectPathwayBtnText: 'More Info',
      coachingDataComplete: false,
      slickOptions: {
        infinite: true,
        autoplay: false,
        dots: false,
        nextArrow:
          '<button class="slick-next  py-1" type="button" style=""><i class="fas fa-chevron-right"></i></button>',
        prevArrow:
          '<button class="slick-prev  py-1" type="button" style=""><i class="fas fa-chevron-left"></button>',
        speed: 300,
        initialSlide: 1,
        slidesToScroll: 0,
        slidesToShow: 3,
        centerMode: true,
        centerPadding: "0",
        variableWidth: false,
        adaptiveHeight: true,
        responsive: [
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 0,
            },
          },
        ],
      },
    };
  },
  mounted() {
    this.getWellbeingCourses();
    this.getUserDetails();
    this.$root.$on('profileQuestionnaireCompleted', ()=> {
      this.showQuestionnaireModal(true);
    })
  },
  created() {
    window.addEventListener('resize', this.calculateWindowWidth);
  },
  destroyed() {
    window.removeEventListener('resize', this.calculateWindowWidth);
  },
  methods: {
    async getUserDetails() {
      const payload = {
        user_id: this.$store.getters.user_id,
      }
      await axios.post(config.api_env + '/application/api/u/user/get-user-profile', payload, config.options)
          .then((response) => {
            this.user_info.user_profile = response.data.user_profile;
            if(this.isCoachingDataCompleted()) {
              this.coachingDataComplete = true
            }
          }, (error) => {
            console.log(error);
          });
    },
    scrollToPathways(){
      const scrollTo = document.getElementById("coachingPathways");
      scrollTo.scrollIntoView({ behavior: "smooth" });
    },
    showQuestionnaireModal() {
      this.$bvModal.show('questionnaire-modal');
    },
    closePathwayModal() {
      this.$bvModal.hide("pathway-modal");
    },
    showPathwayModal(image, title, text) {
      this.pathwayModalImageUrl = image;
      this.pathwayModalText = text;
      this.pathwayModalTitle = title;
      this.$bvModal.show("pathway-modal");
      const pathwayName = title.replace(' ','-').toLowerCase();
      const payload = {
        user_id: this.$store.getters.user_id,
        pathway: pathwayName
      };
      axios.post(config.api_env + "/application/api/u/pathways/set-pathway", payload, config.options);
    },
    async getWellbeingCourses() {
      const payload = {
        user_id: this.$store.getters.user_id,
        limit: 10,
        channel: "surge",
      };
      let res = await axios.post(
        config.api_env + "/application/api-hc/list-surge-courses",
        payload,
        config.options
      );
      this.courses = res.data.courses;
      //destroy and recreate slick carousel
      if (this.$refs.slick) {
        this.$refs.slick.destroy();
      }
      this.$nextTick(function () {
        if (this.$refs.slick) {
          this.$refs.slick.create(this.slickOptions);
        }
      });
    },
    selectCourse(course_id) {
      this.$router.push("/wellbeing/" + course_id).catch((err) => {});
    },
    exploreAll() {
      this.$router.push("/wellbeing").catch((err) => {});
    },
    calculateWindowWidth() {
      this.windowWidth = window.innerWidth
    }
  },
  computed: {
    ...mapState(['user', 'client']),
    componentText() {
      return this.getText.loggedIn.home.homeWellbeing;
    },
  },
};
</script>
<style lang="less">
#pathway-modal {
  .modal-content {
    border-radius: 24px;
    overflow: hidden;
  }
  .modal-body {
    padding: 0;
    color: white;
  }
  .close-button {
    cursor: pointer;
  }
  .pathway-content-container {
    border-radius: 16px 16px 0 0;

    h2 {
      font-size: 2.6rem;
    }
    p {
      font-size: 1.6rem;
      padding: 10px;
    }
  }
  .cta-button{
    padding-top: 12.5px;
    padding-bottom: 12.5px;
  }
}
#CoachingEAPHome {
  
  .pathways-subheader{
    font-size: 1.6rem;
    text-align: center;
    max-width: 600px;
    margin: 0 auto;
    padding-bottom: 10px;
  }
  .telephone-link {
    color: white;
  }
  .cta-button-id{
    background-color: #edece5;
    color: #651242
  }
  .cta-button-id:hover {
    color: white;
  }
  .evolve-home-banner-container {
    background: rgb(56, 27, 81);
    background: linear-gradient(
      90deg,
      rgba(56, 27, 81, 1) 0%,
      rgba(155, 86, 131, 1) 100%
    );
    .evolve-home-banner {
      padding-top: 60px;
    }
    .evolve-home-banner-text {
      color: white;
      font-size: 1.6rem;
      z-index: 2;
      .evolve-home-banner-title {
        font-family: FilsonProBold;
        font-size: 2.8rem;
        font-weight: bold;
        padding-bottom: 25px;
        color: white;
      }
      p {
        color: white;
      }
    }
    div {
      z-index: 2;
    }
  }
  .evolve-reviews-container {
    padding-bottom: 30px;
    .evolve-review {
      padding: 20px;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .evolve-review-content {
        font-size: 1.6rem;
      }
      .evolve-review-name {
        font-size: 1.6rem;
        font-weight: bold;
        text-transform: uppercase;
      }
      .evolve-review-location {
        font-size: 1.4rem;
      }
    }
  }
  @media only screen and (max-width: 768px) {
    .evolve-pathways-container {
      grid-template-columns: 1fr !important;
    }
  }
  .evolve-pathways-container {
    padding-bottom: 30px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 10px;
    .evolve-pathway {
      border-radius: 16px;
      margin-top: 20px;
      margin-bottom: 20px;
      padding: 15px;
      display: flex;
      flex-direction: column;
      position: relative;
      overflow: hidden;
      z-index: 2;
      flex-grow: 1;
      justify-content: space-around;
      h4 {
        font-size: 1.8rem;
        font-weight: bold;
        margin-bottom: 15px;
        z-index: 10;
      }
      p {
        max-width: 240px;
        color: white;
        font-size: 1.4rem;
        z-index: 10;
      }
      div {
        align-self: flex-end;
        z-index: 10;
      }
      img {
        position: absolute;
        right: 0px;
        top: 0px;
        z-index: 1;
        width: 70%;
        height: auto;
        overflow: hidden;
      }
    }
  }
  .horizontal-divider {
    flex-grow: 1;
    margin-left: 20px;
    border: 2px solid;
  }
  .banner-overlay {
    position: absolute;
    width: 100%;
    bottom: 100%;
    z-index: 1;
    img {
      width: 100%;
    }
  }
  .pathway-overlay {
    position: absolute;
    width: 100%;
    bottom: 0;
    z-index: 1;
    img {
      width: 100%;
    }
  }
  .pathway-blob {
    position: absolute;
    width: 100%;
    bottom: 0;
    right: 0;
    z-index: 1;
    div {
    }
  }
  .yellow-button{
    background-color: #f1c361 !important;
  }
  .green-button{
    background-color: #65ceac !important;
  }
  #EAPNumbers {
    background-color: #FAFAFA;
    margin: 0;
    width: 100%;
    p {
      font-size: 16px;
      text-align: center;
      font-family: FilsonProBold;

    }
    .eap-number-box {
      font-size: 16px;
      text-align: center;
      a {
      }
    }
  }
}
</style>
