

<template>
  <div id="NavModeToggler">
    <div class="text-center darkModeText">{{ Toggle }}</div>
    <div class="switch-holder mt-2">
      <div class="switch" :class="showThemeSelectInNav ? 'toggled': 'untoggled'" @click="toggle">
        <div class="switch-text">{{showThemeSelectInNav ? 'ON' : 'OFF'}}</div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "NavThemeToggler",
  props: ['nav_elements'],
  data() {
    return {

    }
  },
  methods: {
    toggle() {
      this.$store.dispatch("toggleShowThemeSelectInNav")
    },
  },
  computed: {
    ...mapGetters(["showThemeSelectInNav"]),
  }
}
</script>

<style lang="less">
#NavModeToggler {
  width: 100px;
  padding-right: calc(((100% - 1170px) / 2) - 125px);
  font-size: 1.4rem;
  line-height: 1.4rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
  .darkModeText {
    color: #060606;
    &.darkMode {
      color: white;
    }
  }
  .switch-holder {
    display: flex;
    align-items: center;
    justify-content: center;
    .switch {
      width: 50px;
      height: 19px;
      border-radius: 20px;
      cursor: pointer;
      background-color: #56554D;
      position: relative;
      .switch-text {
        color: white;
        width: 100%;
        height: 100%;
        line-height: 18px;
        font-size: 0.85rem;

      }
      &::after {
        content: '';
        width: 15px;
        height: 15px;
        background-color: #937FF8;
        border-radius: 50%;
        position: absolute;
        top: 2px;
        left: 3px;
        transition: transform 0.25s ease-out;
      }
      &.untoggled {
        background-color: #D3D3D3;
        .switch-text {
          text-align: right !important;
          padding-right: 5px;
          transform: translate(0px, 0px);
        }
      }
      &.toggled {
        .switch-text {
          text-align: left !important;
          padding-left: 8px;
        }
        &::after {
          top: 2px;
          transform: translate(30px, 0px) rotate(180deg);
        }
      }
    }
  }
}
@media only screen and (min-width: 768px) and (max-width: 992px) {
  .dark-mode-container {
    grid-column: 4;
    grid-row: 1;
  }
}
@media only screen and (max-width: 767px) {
  .dark-mode-container {
    padding-bottom: 5px;
    width: 100%;
    .switch-holder {
      margin-top: 0 !important;
      margin-left: 5px !important;
    }
  }
}
</style>