<template>
  <div id="SearchBar" @mouseleave="hideResults" @mouseover="searchResultsVisible = true">
    <div class="innerSearchBar">
      <div class="leftBorder"></div>
      <div class="iconHolder">
        <i class="fa-solid fa-magnifying-glass" v-if="!searching"></i>
        <b-spinner label="Spinning" v-else></b-spinner>
      </div>
      <b-form-input v-model="searchTerm" :placeholder="placeholder" type="search" v-on:keyup="handleSearchInput" v-on:keyup.enter="handleSearchInput(false)"/>
    </div>
      <div class="resultsBackground" >
        <div class="resultsContainer" @scroll="scroll" ref="resultsContainer" v-bind:style="{maxHeight: resultContainerMaxHeight + 'px'}" v-if="searchResults.length >= 1 && searchResultsVisible">
          <SearchBarResult v-for="result in searchResults" :result="result" @newSearch="newSearch"/>
        </div>
        <div v-else-if="noResults && searchResultsVisible" class="resultsContainer noResults">
          Sorry, no results matching your search were found.
        </div>
        <div v-else-if="error && searchResultsVisible" class="resultsContainer noResults">
          Sorry but something went wrong.  Please try again later
        </div>
      </div>
  </div>

</template>

<script>

import SearchBarResult from "@/components/SearchBarResult.vue";
import EventBus from "@/helpers/eventbus";
export default {
  name: "SearchBar",
  components: {SearchBarResult},
  props: {
    placeholder: {
      type: String,
      default: "What are you looking for..."
    },
  },
  data() {
    return {
      searchTerm: null,
      searchResults: [],
      searchResultsVisible: false,
      searching: false,
      total_pages: 1,
      current_page: 1,
      resultContainerMaxHeight: 600,
      noResults: false,
      error: false
    }
  },
  computed: {
  },
  watch: {
    searchTerm() {
      this.noResults = false;
      this.error = false;
      this.searchResults = []
    }
  },
  methods: {
    hideResults () {
      this.searchResultsVisible = false;
      this.noResults = false;
    },
    scroll() {
      const percentageScrolled = (this.$refs.resultsContainer.scrollTop + this.$refs.resultsContainer.clientHeight) / this.$refs.resultsContainer.scrollHeight;
      if(this.current_page < this.total_pages && percentageScrolled > 0.85) this.search(true);
      EventBus.$emit("searchBarScroll");
    },
    handleSearchInput(setDelay = true) {
      this.current_page = 1;
      this.total_pages = 1;
      if(this.searchTimeout) {
        clearTimeout(this.searchTimeout);
      }
      if(setDelay) {
        if(this.searchTerm.length > 2) {
          this.searchTimeout = setTimeout(this.search, 2000);
        }
      } else {
        this.search();
      }
    },
    newSearch(term) {
      this.searchTerm = term;
      this.handleSearchInput(false);
    },
    async search(incrementPage = false) {
      try {
        if(this.searching) return;
        this.searching = true;
        if(incrementPage) this.current_page ++;
        let res = await this.api({
          path: "api/u/content/search",
          query: this.searchTerm,
          page: this.current_page,
          page_size: 10
        });
        if (res.success) {
          if(incrementPage) {
            this.searchResults = this.searchResults.concat(res.data);
          } else {
            this.searchResults = res.data;
            if(!res.data.length) {
              this.noResults = true;
            }
          }
          this.searchResultsVisible = true;
          this.current_page = res.meta.pagination.current_page;
          this.total_pages = res.meta.pagination.total_pages
        }
        this.searching = false
      } catch (e) {
        this.searching = false;
        this.searchResultsVisible = true;
        this.error = true;
      }

    }
  },
}
</script>

<style lang="less">
#SearchBar {
  height: fit-content;
  width: 100%;
  max-width: 600px;
  padding: 15px 10px 10px 5px;
  margin-left: 10px;
  position: relative;
  z-index: 500;
  .innerSearchBar {
    z-index: 501;
    position: relative;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 5px 10px 5px 25px;
    background-color: #DEDEDE;
    .iconHolder {
      width: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .leftBorder {
      height: 100%;
      width: 10px;
      position: absolute;
      top: 0;
      left: 0;
    }
    input {
      width: 100%;
      font-size: 18px;
      line-height: 15px;
      height: 100%;
      background-color: transparent;
      border: none !important;
    }
    i {
      font-size: 19px;
      color: #1E1E1E;
    }
  }
  .resultsBackground {
    width: calc(100% + 25px);
    padding-right: 25px;
    padding-bottom: 25px;
    height: fit-content;
    z-index: 998;
    position: absolute;
  }
  .resultsContainer {
    width: 100%;
    height: fit-content;
    top: calc(100% - 17px);
    background-color: white;
    overflow-y: auto;
    border: grey 1px solid;
    box-shadow: 1px 3px 3px #2f2f2f;
    &.noResults {
      width: 100%;
      height: 50px;
      font-size: 1.6rem;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    &::-webkit-scrollbar {
      width: 12px;
    }

    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 2px rgba(0,0,0,0.5);
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb {
      -webkit-box-shadow: inset 0 0 2px rgba(0,0,0,0.5);
      border-radius: 10px;
    }
  }
}
</style>