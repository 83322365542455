<template>
  <div id="SingleCoach" class="row" :class="getTheme() + ' ' + $store.getters.navType">
    <div v-if="loading" class="col-12 coach-modal-loader">
      <Loader class="my-auto" />
    </div>
    <div class="col-12 coach_modal_inner" v-else>
      <div class="row">
        <div class="col-lg-7 col-md-12 left_col">
          <div
            class="video_wrapper animate__animated animate__fadeIn mb-5"
            v-if="coach.video_url != false"
          >
            <video
              class="video-fluid"
              :src="coach.video_url"
              title="Coach video"
              controls
            />
          </div>
          <div class="avatar_wrapper mb-5">
            <div class="row">
              <div class="col-3">
                <div class="avatar_box">
                  <img
                    :src="coach.avatar_url"
                    title="Coach Avatar"
                    :alt="coach.name"
                  />
                </div>
              </div>
              <div class="col-9 text">
                <h1>{{ coach.name }}</h1>
                <p>
                  <i class="fas fa-check-circle brand-highlight-1"></i>
                  {{ coach.position }} <br />
                  <i class="fas fa-graduation-cap brand-highlight-1"></i>
                  {{ coach.degree }}
                </p>
              </div>
            </div>
          </div>
          <div class="info_wrapper mb-5">
            <div v-html="coach.description_long">
            </div>
          </div>
          <!--          <hr class="horizontal-divider mb-5" />-->
          <!--          <div class="reviews_wrapper">-->
          <!--            <div class="review">-->
          <!--              <p class="review_text">-->
          <!--                “{{ coach.name }} is amazing! Helped me so much over the past 6-->
          <!--                months achieve to my goals”-->
          <!--              </p>-->
          <!--              <p class="reviewer">-->
          <!--                - This commenter has preferred to be anonymous-->
          <!--              </p>-->
          <!--            </div>-->
          <!--            <div class="review">-->
          <!--              <p class="review_text">-->
          <!--                “Couldn’t recommend {{ coach.name }} more! My main objective was-->
          <!--                to slow down, and become more present in my everyday life,-->
          <!--                working with {{ coach.name }} has done so much for me”-->
          <!--              </p>-->
          <!--              <p class="reviewer">- Ross W</p>-->
          <!--            </div>-->
          <!--          </div>-->
          <div class="buttons-container d-flex justify-content-center">
            <!--            <b-button variant="outline-purple">Read More Reviews</b-button>-->
            <b-button @click="closeModal()" class="outline-button"
              >See Other Coaches</b-button
            >
          </div>
        </div>
        <div class="col-lg-5 col-md-12 right_col">
          <div class="">
            <div class="dates mb-4">
              <h4 class="mb-3">What date suits you ?</h4>
              <slick ref="slick" :options="slickOptions" class="w-100">
                <b-button
                  @click="selectDate(e)"
                  v-for="(e, e_i) in events"
                  :key="e_i"
                  :class="
                    selected_date.date === e.date
                      ? 'cta-button'
                      : 'outline-button'
                  "
                  >{{ getShortDate(e.date) }}</b-button
                >
              </slick>
            </div>
            <div v-if="selected_date" class="slots mb-4">
              <h4 class="mb-3">
                What time works best for you on
                {{ getDayStringLong(selected_date.date) }} ?
              </h4>
              <div class="row mb-4">
                <div
                  v-for="(t, t_i) in selected_date.slots"
                  :key="t_i"
                  class="col-4 my-2"
                >
                  <b-button
                    @click="selectSlot(t)"
                    block
                    :class="
                      t.id === selected_slot.id
                        ? 'cta-button'
                        : 'outline-button'
                    "
                    >{{ getHoursMinutes(t.start, true) }}</b-button
                  >
                </div>
              </div>
            </div>
            <div class="info">
              <p class="mb-3" v-if="selected_slot">
                You have selected
                <b>{{ getFormatedDate(selected_slot.start, "DD/MM/YY") }}</b> at
                <b>{{ getHoursMinutes(selected_slot.start, true) }}</b> with
                <b>{{ coach.name }}</b>
              </p>
              <p>
                By booking an appointment, you agree to the following
                <a target="_blank" href="/terms-and-conditions"
                  >terms & conditions</a
                ><br /><br />
                <b>What to expect on your first session?</b><br /><br />
                Bringing change in our lives can feel quite daunting at first.
                As you book your first coaching session, here are some things
                that you can expect:<br /><br />
                <b>1.</b> Remember that you will be well supported on this journey by
                your trained Mental Health Coach.<br />
                <b>2.</b> The first coaching session is designed for you and your coach
                to get to know each other a little better. <br />
                <b>3.</b> Your coach would be keen to know more about you, about the
                goals you want to achieve, and about the pace that you are able
                to go at this time.<br />
                <b>4.</b> Some information about different coaching pathways and
                policies around confidentiality will also be discussed with you
                during this first session.
                <br /><br />
                Once you book your kick-off session, you can also start to
                message your coach. If you have any concerns or issues on the
                way, please reach out and we’ll be happy to assist
                <br />
                <br />
              </p>
            </div>
            <div class="row" v-if="!booked">
              <b-button
                v-if="selected_slot"
                @click="bookSlot"
                class="cta-button mx-auto px-5"
                >Book Now</b-button
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loader from "../Loader";
import slick from "vue-slick";
import EventBus from "@/helpers/eventbus";

export default {
  name: "EvolveSingleCoach",
  components: { Loader, slick },
  props: ["coach_id"],
  data() {
    return {
      loading: true,
      coach: {},
      events: [],
      selected_date: false,
      selected_slot: false,
      selected_delivery_type: "video",
      booked: false,
      slickOptions: {
        infinite: false,
        autoplay: false,
        dots: false,
        nextArrow:
          '<button class="slick-next mt-2" type="button" style=""><i class="fas fa-caret-right brand-highlight-1"></i></button>',
        prevArrow:
          '<button class="slick-prev mt-2" type="button" style=""><i class="fas fa-caret-left brand-highlight-1"></button>',
        slidesToScroll: 3,
        slidesToShow: 3,
        centerPadding: "0",
        adaptiveHeight: true,
      },
    };
  },
  mounted() {
    this.getCoach(this.coach_id);
  },
  methods: {
    closeModal() {
      this.$bvModal.hide("single_coach_modal");
    },
    async bookSlot() {
      let self = this;
      let p = {
        path: "api/u/coaching-event/book-session",
        slot_id: this.selected_slot.id,
        delivery_type: this.selected_delivery_type,
      };
      let res = await this.api(p);
      if (res.success) {
        this.booked = true;
        this.$store.dispatch("setCoach", {
          title: self.coach.name,
          avatar: self.coach.avatar_url,
        });
        this.$bvModal.hide("single_coach_modal");
        this.$router.push("/total-mental-health");
        EventBus.$emit('updateDFDReminders');
      } else {
        this.$root.$emit("bookingFail");
        this.$bvModal.hide("single_coach_modal");
      }
    },
    selectSlot(t) {
      if (!this.booked) {
        this.selected_slot = t;
      }
    },
    selectDate(e) {
      this.selected_slot = false;
      if (!this.booked) {
        this.selected_date = e;
      }
    },
    setDeliveryType(type) {
      if (!this.booked) {
        this.selected_delivery_type = type;
      }
    },
    async getCoach(id) {
      let p = {
        path: "api/u/coach/get-coach",
        coach_id: id,
      };
      let res = await this.api(p);
      if (res.success) {
        this.coach = res.coach;
        this.events = res.events;
        this.loading = false;
      }
    },
    // async chooseCoach(id){
    //     let p = {
    //         path : 'api/u/coach/choose-coach',
    //         coach_id : id
    //     }
    //     let res = await this.api(p)
    //     if(res.success){
    //         this.$router.push("/coaching");
    //     }
    // }
  },
};
</script>
<style lang="less">
#SingleCoach {
  font-family: "DMSans", Arial, Helvetica, sans-serif;
  .slick-next {
    right: -25px;
  }
  .slick-prev {
    left: -15px;
  }
  .coach-modal-loader {
    min-height: 800px;
    display: flex;
    align-items: center;
  }
  .coach_modal_inner {
    font-size: 1.6rem;
    .left_col {
      padding: 30px;
      .video_wrapper {
        position: relative;
        text-align: center;
        padding-bottom: 56.25%;
        img {
          max-width: 100%;
        }
        .video-fluid {
          border-radius: 16px;
          overflow: hidden;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: auto;
        }
      }
      .avatar_wrapper {
        .avatar_box {
          text-align: center;
          img {
            border-radius: 50%;
            max-width: 80%;
            box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.2);
          }
        }
      }
      .info_wrapper {
      }
      .reviews_wrapper {
        .review {
          .review_text {
            font-weight: bold;
          }
          .reviewer {
            font-weight: lighter;
          }
        }
      }
      .buttons-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
      }
    }

    .right_col {
      h4 {
        font-weight: bold;
      }
      background-color: #f7f7f7;
      padding: 30px;
      h2 {
        font-weight: bold;
      }
    }
  }
}
</style>
